import { StyleSheet } from "react-native";
import useDeviceSize from "./useDeviceSize";
// Will recursively combine all the styles as StyleSheet.compose only takes two arguments
const composeMultipleStyles = (styles, classNames) => {
    // Base cases to return the style if there are not two for a composition
    if (classNames.length === 1)
        return styles[classNames[0]];
    // Recursively combine the rest of the styles
    const nextIteration = composeMultipleStyles(styles, classNames.slice(1));
    return StyleSheet.compose(styles[classNames[0]], nextIteration);
};
const buildCustomStyleMap = (styles, size) => {
    const styleObj = {};
    // Will match any string that contains the sizes (in any order) followed by the class name
    const matchOverrides = (defaultClass) => new RegExp(`(^\\$\\$|.*\\+)${size}[a-zA-Z0-9+-]*\\$\\$_${defaultClass}$`);
    // Will get a list the base styles excluding all overrides
    const defaultKeys = Object.keys(styles).filter((style) => !style.startsWith('$$'));
    defaultKeys.forEach((key) => {
        // Get all overrides for a certain class
        const styleOverrides = Object.keys(styles).filter((style) => style.match(matchOverrides(key)));
        // Combine the base style and any overrides to get the computed style
        styleObj[key] = composeMultipleStyles(styles, [key, ...styleOverrides]);
    });
    return styleObj;
};
export default function useResponsiveStyle(styles) {
    return () => {
        const size = useDeviceSize();
        return buildCustomStyleMap(styles, size);
    };
}
